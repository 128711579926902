import React from 'react';
import { Button, Box } from '@mui/material';
import InputField from '../../elements/input';
import { targetMarketOption, views } from '../../../_constants_';
import { convertDateWithTimezone, getLabelById } from '../../../_helpers_';

export const ShowProductLink = ({ productLink, changeView }) => {

  return (
    <Box display={'flex'} flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="flex-start" mb={1} gap={1}>
        <Button
          variant="contained"
          onClick={() => {
            changeView(views.edit);
          }}
        >
          Edit
        </Button>
      </Box>
      {productLink && (
        <Box display="flex" flexDirection="column" gap={2.5}>
          <InputField
            disabled
            title="Request ID"
            value={`Request ${productLink?.id}`}
          />

          <InputField disabled title="Timestamp" value={convertDateWithTimezone(productLink?.timestamp)} />

          <InputField
            disabled
            title="Status"
            value={productLink?.status}
          />

          <InputField title="Reason" value={productLink?.note} disabled />

          <InputField
            disabled
            title="Product type"
            value={productLink?.industry?.label}
          />

          <InputField
            disabled
            title="Product URLs"
            value={productLink?.url}
          />

          <InputField
            disabled
            title="Target market"
            value={getLabelById(+ productLink?.target_market, targetMarketOption)}
          />

          <InputField
            disabled
            title="Ads account"
            value={productLink?.acc_name}
          />

          <InputField
            disabled
            title="Team ID"
            value={productLink?.team_id}
          />
        </Box>
      )}
    </Box>
  );
};
