import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Button, Box, Typography, IconButton } from '@mui/material';
import InputField from '../../elements/input';
import SelectSearch from '../../elements/select-search';
import { PER_PAGE, refundStatus, views } from '../../../_constants_';
import { ShowRefund } from './show';
import CloseIcon from '@mui/icons-material/Close';
import Page from '../../Page';
import { useDispatch, useSelector } from 'react-redux';
import { clearData } from '../../../_actions_';
import TextareaField from '../../elements/textarea';
import { getListRefund, updateRefund } from '../../../_actions_/refund.actions';
import { formatDateToTimezone } from '../../../_helpers_';
import UploadSingleImage from '../../elements/upload-file-single';

const DrawerEditRefund = ({ open, onClose, refund }) => {
  const [dataForm, setDataForm] = useState({});
  const [view, setView] = useState(views.show);
  const [errorForm, setErrorForm] = useState('');
  const dispatch = useDispatch();
  const dataEditSuccess = useSelector((state) => state?.refund?.dataEditSuccess);
  const editRefundErrors = useSelector((state) => state?.refund?.editRefundErrors);

  const handleChangeDataForm = (key, value) => {
    setDataForm({ ...dataForm, [key]: value });
  };

  useEffect(() => {
    if (refund)
      setDataForm({
        status: refund.status,
        note: refund?.note ?? ''
      });
  }, [refund]);

  useEffect(() => {
    if (dataEditSuccess && refund.team_id) {
      dispatch(
        getListRefund({
          page: 1,
          per_page: PER_PAGE
        })
      );
      handleClose();
      dispatch(clearData('dataEditSuccess'));
    }
  }, [dataEditSuccess]);

  useEffect(() => {
    if (editRefundErrors) {
      setErrorForm(editRefundErrors.msg?.errors);
      dispatch(clearData('editRefundErrors'));
    }
  }, [editRefundErrors]);

  const handleChangeView = (value) => {
    setView(value);
  };

  const handleSubmit = async () => {
    dispatch(updateRefund(refund.id, dataForm));
  };

  const btnSubmit = useMemo(() => {
    let valid = refund.id && dataForm.status;

    return (
      <Button variant="contained" disabled={!valid} onClick={valid ? handleSubmit : null}>
        Save
      </Button>
    );
  }, [refund, dataForm]);

  const handleClose = () => {
    onClose();
    setDataForm({});
    if (errorForm) setErrorForm('');
  };

  return (
    <Drawer anchor="right" open={open}>
      <Box sx={{ width: 600, padding: 2 }}>
        <Box>
          <Typography variant="h4" mb={2}>
            {view === views.show ? 'Refund detail' : 'Refund edit'}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose();
              setView(views.show);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {view === views.show ? (
          <Page title="SpendAds | Request Refund Detail">
            <ShowRefund refund={refund} changeView={handleChangeView} />
          </Page>
        ) : (
          <Page title="SpendAds | Request Refund Edit">
            <Box display="flex" flexDirection="column" gap={2}>
              <Box display="flex" justifyContent="flex-start" mb={1} gap={1}>
                {btnSubmit}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    handleChangeView(views.show);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Box display="flex" flexDirection="column" gap={2.5}>
                <InputField title="Request ID" value={`Request ${refund?.id}`} disabled />
                <InputField
                  title="Timestamp"
                  value={formatDateToTimezone(refund?.create_date)}
                  disabled
                />

                <SelectSearch
                  title={'Status'}
                  placeholder={'Status'}
                  value={dataForm.status}
                  onChange={(newValue) => {
                    handleChangeDataForm('status', newValue ? newValue.value : '');
                  }}
                  options={refundStatus}
                  required
                  error={errorForm.status}
                />
                {refund.status === refundStatus[1].value &&
                dataForm.status === refundStatus[1].value ? (
                  <UploadSingleImage
                    title="Upload Refund Proof"
                    defaultFile={refund?.proof_image}
                    disabled
                    required
                  />
                ) : dataForm.status === refundStatus[1].value ? (
                  <UploadSingleImage
                    title="Upload Refund Proof"
                    description="Refund evidence refers to a document or image confirming the completion of the refund transaction. Please upload a PNG, JPG, JPEG, or PDF file."
                    onChange={(files) => handleChangeDataForm('proof_image', files)}
                    required
                    error={errorForm?.proof_image}
                  />
                ) : null}

                <InputField
                  title="Note"
                  placeholder="Please provide a reason if applicable"
                  value={dataForm.note}
                  onChange={(event) => handleChangeDataForm('note', event.target.value)}
                  error={errorForm.note}
                />

                <InputField disabled title="Requested Amount" value={`$ ${refund?.amount}`} />

                <InputField disabled title="Refund reason" value={refund?.refund_reason} />

                <TextareaField title="Detail reason" value={refund.detail_reason} disabled />

                <InputField disabled title="Receiving method" value={refund?.receive_method} />

                <InputField disabled title="Recipient information" value={refund?.receiving_info} />

                <InputField disabled title="Team ID" value={refund?.team_id} />
              </Box>
            </Box>
          </Page>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerEditRefund;
