import { request } from '../../../_services_';
import { IconBrandMessenger } from '@tabler/icons';
import { makeId } from '../../../utils';

export const listFields = [
  {
    name: 'mess_group_link',
    label: '',
    renderValue: (item) => item?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
     className='d-flex align-items-center' 
     href={item?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
    width: '3rem',
  },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id ? `Team_${item.team_id}` : '',
    width: '6rem',
    sort: true,
    sortName: 'team_id',
    filter:true,
    filterName:'team_search|owner_name*1'
  },
  {
    name: 'type_acc',
    label: 'Acc Type',
    width: '6rem',
    sort: true,
    filter:true,
  },
  {
    name: 'sale_convert',
    label: 'Sale convert',
    width: '7rem',
    sort: true,
    filter:true,
  },
  {
    name: 'content_file',
    label: 'Content File (URL)',
    sort: true,
    filter:true,
    renderValue: (item) => item.content_file ? <a style={{ textDecoration: 'underline' }} onClick={(e) => e.stopPropagation()} href={item.content_file} className="line-clamp-1" target="_blank">{item.content_file}</a> : ''
  },
  {
    name: 'teamlead_shortname',
    label: 'User Shortname',
    renderValue: (item) => item.teamlead_shortname || '',
    width: '10rem',
    sort: true,
    filter:true,
  },
  {
    name: 'mess_group_link',
    label: 'Mess Group Link',
    width: '16rem',
    renderValue: (item) => item?.mess_group_link,
    sort: true,
    // filter:true,
  },
  {
    name: 'discount',
    label: 'Chiết khấu %',
    renderValue: (item) => item.discount ? `${item.discount}%` : '',
    width: '7rem',
    sort: true,
  },
  {
    name: 'platform_id',
    label: 'Platform',
    renderValue: (item) => item.platform_id?.title,
    width: '6rem',
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id ? `Team_${item.team_id}` : '',
    disabled: true
  },
  {
    type: 'text',
    name: 'mess_group_link',
    label: 'Mess Group Link',
    suffix: {
      visible: (item) => item.mess_group_link && item.mess_group_link != "",
      text: 'Copy',
      onClick: (item, ctx) => {
        navigator.clipboard.writeText(item.mess_group_link)
        const openSnackbar = {
          open: true,
          text: 'Copied'
        }
        ctx.props.dispatch({ type: 'OPEN_SNACKBAR', openSnackbar })
      }
    }
  },
  {
    type: 'select',
    name: 'platform_id',
    label: 'Platform',
    loadOptions: (inputValue) => {
      return new Promise(resolve => {
        request.get('/api/v1/team_platforms', {search: inputValue}).then(res => {
          if (res.data.success) {
            resolve(res.data.data.items)
          }
        })
      })
    },
    valueKey: "id",
    labelKey: "title",
    onChange: (e, ctx) => {
      const formState = Object.assign({}, ctx.state.formState)
      formState.values[e.target.name] = e.target.value
      formState.changes[e.target.name] = e.target.value
      delete formState.values['type_acc']
      delete formState.changes['type_acc']
      const options = Object.assign({}, ctx.state.options)
      options['type_acc'] = e.target.value.type_acc
      ctx.setState({ formState, options })
    }
  },
  {
    type: 'select_option',
    name: 'type_acc',
    label: 'Acc Type',
    renderValue: (item) => item.type_acc ? {id: makeId(10), name: item.type_acc} : null,
    loadOptions: (inputValue, item) => {
      return new Promise(resolve => {
        let result = []
        if (item.platform_id) {
          result = item.platform_id.type_acc?.filter(e => e.name.toLowerCase().includes(inputValue.toLowerCase()))
        }
        resolve(result)
      })
    },
    valueKey: 'id',
    labelKey: 'name',
    options: (item, ctx) => ctx.state.options['type_acc']?.map(e => ({ id: e.id, name: e.name })) || [],
    disabled: (values) => values.platform_id ? false : true,
    onChange: (e, ctx) => {
      const formState = Object.assign({}, ctx.state.formState)
      formState.values[e.target.name] = e.target.value.name
      formState.changes[e.target.name] = e.target.value.name
      ctx.setState({ formState })
    }
  },
  {
    type: 'text',
    name: 'sale_convert',
    label: 'Sale convert',
  },
  {
    type: 'text',
    name: 'content_file',
    label: 'Content File (URL)',
    suffix: {
      visible: (item) => item.content_file && item.content_file != "",
      text: 'Copy',
      onClick: (item, ctx) => {
        navigator.clipboard.writeText(item.content_file)
        const openSnackbar = {
          open: true,
          text: 'Copied'
        }
        ctx.props.dispatch({ type: 'OPEN_SNACKBAR', openSnackbar })
      }
    }
  },
  {
    type: 'text',
    name: 'teamlead_shortname',
    label: 'User Shortname',
    renderValue: (item) => item.teamlead_shortname || '',
    disabled: true
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email',
    disabled: true
  },
  {
    type: 'number',
    name: 'discount',
    label: 'Chiết khấu (%)',
    renderValue: (item) => item.discount && item.discount,
  },
  {
    type: 'number',
    name: 'refund',
    label: 'Refund',
  },
]