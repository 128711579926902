import React, { useState } from 'react'
import { listFields } from './fields';
import { ResourceList, ModalForm, DrawerForm } from '../../../components';
import AddIcon from '@mui/icons-material/Add';
import {
  TextField, Box,
} from '@mui/material';
import AsyncSelect from 'react-select/async';
import { customStyles, loadOptions, handleInputChange } from '../../../utils';
import { toastr } from '../../../_helpers_';
import { request } from '../../../_services_';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { formFields } from './fields'
import { modeScreen } from '../../../_constants_';
import ModalConfirm from '../../../components/setting/modal/modal-confirm';

const AllUserList = (props) => {
  const { navigate } = props;
  const [drawerValue, setDrawerValue] = useState({
    open: false,
    objectId: null,
    mode: modeScreen.view,
    modalConfirmDelete: false,
    userDeleteId: null,
    reload: false
  });

  const handleClickRow = (item) => {
    let newValue = Object.assign({}, drawerValue)
    newValue.open = true
    newValue.objectId = item.public_id
    setDrawerValue(newValue)
  }

  const handleClose = () => {
    let newValue = Object.assign({}, drawerValue)
    newValue.open = false
    newValue.objectId = null
    newValue.mode = 'view'
    setDrawerValue(newValue)
  }

  const handleDeleteUser = () => {
    if (drawerValue.userDeleteId) {
      request.delete(`/api/v1/user/${drawerValue.userDeleteId}`)
        .then(res => {
          if (res.data.success) {
            toastr.success("Delete successful");
            // Close drawer and reload
            setDrawerValue(prev => ({
              ...prev,
              open: false,
              mode: modeScreen.view,
              reload: true
            }));
          } else {
            toastr.error(res.data.msg);
          }
        })
        .catch(err => {
          toastr.error("There was an error delete data");
        })
        .finally(() => {
          // Reset modal confirm và userDeleteId
          setDrawerValue(prev => ({
            ...prev,
            modalConfirmDelete: false,
            userDeleteId: null
          }));
        });
    }
  }

  const newUser = (ctx) => {
    ModalForm.instance.current.openForm({
      title: 'New User',
      data: {},
      customView: (submitData, handleChange, ctx) => (
        <div className='p-2'>
          <div>
            <Box fontSize={15} fontWeight="bold">
              Email Address <span className='field-require'>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="email"
              value={submitData.values.email}
              variant="outlined"
              onChange={handleChange}
              type="email"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors.email}
              helperText={submitData.errors.email ? submitData.errors.email[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User Role <span className='field-require'>*</span>
            </Box>
            <AsyncSelect
              className="MuiFormControl-marginDense"
              loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                return new Promise(resolve => {
                  request.get('/api/v1/groups', { name: inputValue, exclude: JSON.stringify(['admin']) }).then(res => {
                    if (res.data.success) {
                      resolve(res.data.data.items.map(e => ({ id: e.code, name: e.name })))
                    }
                  })
                })
              })}
              defaultOptions
              onInputChange={handleInputChange}
              isSearchable
              name="role"
              onChange={(value) => {
                const event = {
                  target: {
                    name: 'role',
                    value
                  }
                }
                handleChange(event)
              }}
              placeholder={"User role"}
              menuPortalTarget={document.body}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              valueKey={"id"}
              value={submitData.values.role}
              styles={submitData.errors?.role ? errorStyles : customStyles}
            />
          </div>
          {submitData.values.role && ['teamlead', 'seller'].includes(submitData.values.role.id) &&
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Company <span className='field-require'>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/companies', { search: inputValue, type: 'customer' }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items.map(e => ({ id: e.company_id, name: e.name })))
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name="company_id"
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'company_id',
                      value,
                      depends: [{
                        route: '/api/v1/teams',
                        query: { 'company_id': value.id },
                        key: 'team_id'
                      }]
                    }
                  }
                  handleChange(event)
                }}
                placeholder={"Company"}
                menuPortalTarget={document.body}
                getOptionLabel={({ id, name }) => `Company_${id} ${name}`}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData.values.company_id}
                styles={customStyles}
              />
              {submitData.errors?.company_id && <span style={{ color: 'red' }}>{submitData.errors?.company_id[0]}</span>}

            </div>
          }
          {submitData.values.role && ['partner'].includes(submitData.values.role.id) &&
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Company <span className='field-require'>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/companies', { search: inputValue, type: 'partner' }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items.map(e => ({ id: e.company_id, name: e.name })))
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name="company_id"
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'company_id',
                      value
                    }
                  }
                  handleChange(event)
                }}
                placeholder={"Company"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name, id }) => `Company_${id} ${name}`}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData.values.company_id}
                styles={customStyles}
              />
              {submitData.errors?.company_id && <span style={{ color: 'red' }}>{submitData.errors?.company_id[0]}</span>}

            </div>

          }
          {submitData.values.company_id && ['teamlead', 'seller'].includes(submitData.values.role.id) &&
            <div className='mt-2'>
              <Box fontSize={15} fontWeight='bold'>
                Team <span className='field-require'>*</span>
              </Box>
              <AsyncSelect
                className="MuiFormControl-marginDense"
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/teams', { search: inputValue, company_id: submitData.values.company_id.id || 0 }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items.map(e => ({ id: e.team_id, name: e.name })))
                      }
                    })
                  })
                })}
                defaultOptions={ctx.state.options['team_id']?.map(e => ({ id: e.team_id, name: e.name })) || []}
                onInputChange={handleInputChange}
                isSearchable
                name="team_id"
                onChange={(value) => {
                  const event = {
                    target: {
                      name: 'team_id',
                      value
                    }
                  }
                  handleChange(event)
                }}
                placeholder={"Team"}
                menuPortalTarget={document.body}
                getOptionLabel={({ id, name }) => `Team_${id} ${name}`}
                getOptionValue={({ id }) => id}
                valueKey={"id"}
                value={submitData.values.team_id}
                styles={customStyles}
              />
              {submitData.errors?.team_id && <span style={{ color: 'red' }}>{submitData.errors?.team_id[0]}</span>}

            </div>
          }
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User Fullname <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="fullname"
              onChange={handleChange}
              value={submitData.values.fullname}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.fullname}
              helperText={submitData.errors?.fullname ? submitData.errors.fullname[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User Shortname <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="shortname"
              onChange={handleChange}
              value={submitData.values.shortname}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.shortname}
              helperText={submitData.errors?.shortname ? submitData.errors.shortname[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User FB profile name
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="facebook_profile_name"
              onChange={handleChange}
              value={submitData.values.facebook_profile_name}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User FB link <span style={{ color: 'red' }}>*</span>
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="facebook_link"
              onChange={handleChange}
              value={submitData.values.facebook_link}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.facebook_link}
              helperText={submitData.errors?.facebook_link ? submitData.errors.facebook_link[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              User - Phone number
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="phone"
              onChange={handleChange}
              value={submitData.values.phone}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.phone}
              helperText={submitData.errors?.phone ? submitData.errors.phone[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Paypal Account
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="paypal_account"
              onChange={handleChange}
              value={submitData.values?.paypal_account}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.paypal_account}
              helperText={submitData.errors?.paypal_account ? submitData.errors.paypal_account[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Payoneer Account
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="payoneer_account"
              onChange={handleChange}
              value={submitData.values?.payoneer_account}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.payoneer_account}
              helperText={submitData.errors?.payoneer_account ? submitData.errors.payoneer_account[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Bank name
            </Box>
            <AsyncCreatableSelect
                className="MuiFormControl-marginDense"
                cacheOptions
                loadOptions={(inputValue) => loadOptions(inputValue, function loadingData(inputValue) {
                  return new Promise(resolve => {
                    request.get('/api/v1/banks', { search: inputValue }).then(res => {
                      if (res.data.success) {
                        resolve(res.data.data.items)
                      }
                    })
                  })
                })}
                defaultOptions
                onInputChange={handleInputChange}
                isSearchable
                name="bank_id"
                onChange={(value) => {
                  var e = {
                    target: {
                      name: "bank_id",
                      value
                    },
                  };
                  handleChange(e)
                }}
                placeholder={"Bank"}
                menuPortalTarget={document.body}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ bank_id }) => bank_id}
                valueKey={"bank_id"}
                value={submitData?.values?.bank_id || null}
                styles={submitData?.errors?.bank_id ? errorStyles : customStyles}
                getNewOptionData={(inputValue, optionLabel) => ({
                  id: inputValue,
                  name: 'Create \"' + inputValue + '\"...',
                  __isNew__: true
                })}
                onCreateOption={(inputValue) => {
                  request.post('/api/v1/banks', { name: inputValue }).then(res => {
                    if (res.data.success) {
                      var e = {
                        target: {
                          name: "bank_id",
                          value: res.data.data
                        },
                      };
                      handleChange(e)
                    } else {
                      toastr.error(res.data.msg)
                    }
                  }, err => toastr.error(err))
                }}
              />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Bank Acc Number
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="bank_number"
              onChange={handleChange}
              value={submitData.values?.bank_number}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
              error={submitData.errors?.bank_number}
              helperText={submitData.errors?.bank_number ? submitData.errors.bank_number[0] : ''}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Bank Branch
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="bank_branch"
              onChange={handleChange}
              value={submitData.values?.bank_branch}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
          <div className='mt-2'>
            <Box fontSize={15} fontWeight='bold'>
              Account owner
            </Box>
            <TextField
              fullWidth
              margin='dense'
              name="holder_name"
              onChange={handleChange}
              value={submitData.values?.holder_name}
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: 'notchedOutline'
                }
              }}
            />
          </div>
        </div>
      ),
      action: {
        schema: (submitData) => {
          var schema = {
            email: {
              presence: { allowEmpty: false, message: '^Email required' },
              email: {
                message: "^Your email is invalid"
              }
            },
            fullname: {
              presence: { allowEmpty: false, message: '^Fullname required' },
              length: {
                minimum: 3,
                message: '^Fullname is too short'
              }
            },
            shortname: {
              presence: { allowEmpty: false, message: '^Shortname equired' },
              format: {
                pattern: /^[a-zA-Z0-9][a-zA-Z0-9_.]*[a-zA-Z0-9](?<![-?\d+\.?\d*$]{3,}.*)$/,
                message: "^Invalid shortname (Minimum 3 characters, only numbers are not allowed and no special characters allowed except _)"
              },
              length: {
                minimum: 3,
                message: "^Shortname is too short"
              }
            },
            facebook_link: {
              presence: { allowEmpty: false, message: '^Facebook link required' },
              format: {
                pattern: /(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i,
                message: "^Enter correct facebook link!"
              }
            }
          }
          if (['teamlead', 'seller'].includes(submitData.values?.role?.id)) {
            schema['company_id'] = {
              presence: { allowEmpty: false, message: '^Required' }
            }
            schema['team_id'] = {
              presence: { allowEmpty: false, message: '^Required' }
            }
          } else if (['partner'].includes(submitData.values?.role?.id)) {
            schema['company_id'] = {
              presence: { allowEmpty: false, message: '^Required' }
            }
          }
          if (submitData.values.phone != "") {
            schema['phone'] = {
              format: {
                pattern: /^\+?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                message: "^Phone number is not valid"
              },
              length: {
                minimum: 8,
                maximum: 15,
                message: "^Phone needs to have 8-15 characters",
              }
            }
          }
          if (submitData.values.paypal_account != "") {
            schema['paypal_account'] = {
              email: {
                message: "^Paypal account must be an email"
              }
            }
          }
          if (submitData.values.payoneer_account != "") {
            schema['payoneer_account'] = {
              email: {
                message: "^Payoneer account must be an email"
              }
            }
          }
          if (submitData.values.bank_number != "") {
            schema['bank_number'] = {
              format: {
                pattern: /^[0-9]*$/,
                message: "^Bank Acc Number is not valid"
              }
            }
          }
          return schema
        },
        onAction: (submitData, ctx) => {
          return new Promise((resolve) => {

            const query = {}
              const allKeys = Object.keys(submitData.values)
              allKeys.map(key => {
                if (key == 'paygate') {
                  query[key] = submitData.values[key]
                } else if (typeof submitData.values[key] === 'object' || submitData.values[key] instanceof Object) {
                  if(key=='bank_id'){
                    query[key] = submitData.values[key].bank_id
                  }else{
                    query[key] = submitData.values[key].id
                  }
                } else {
                  query[key] = submitData.values[key]
                }
              })
              request.post('/api/v1/users', query).then(res => {
                if (res.data.success) {
                  const items = Object.assign([], ctx.state.items)
                  items.push(res.data.data)
                  ctx.setState({ items, totals: ctx.state.totals + 1 })
                  resolve(true);
                  toastr.success("Create user successful")
                } else {
                  toastr.error(res.data.msg)
                  resolve(false)
                }
              }, err => {
                toastr.error(err);
                resolve(false)
              })

            // submitData = ctx.validateForm(schema)
            // if (!submitData.isValid) {
            //   submitData.isSubmiting = false
            //   ctx.setState({ submitData })
            //   const errors = []
            //   Object.keys(submitData.errors).map(key => (
            //     submitData.errors[key].map(error => (
            //       errors.push(error)
            //     ))
            //   ))
            //   toastr.error(errors.join('<br>'))
            //   resolve(false)
            //   return
            // } else {
              
            // }
          })

        }
      }
    })
  }

  const handleSave = (ctx, formState) => {
    const query = {};
    const allKeys = Object.keys(formState.changes);
    allKeys.map(key => {
      query[key] = formState.changes[key]
    })

    request.put(`/api/v1/user/${formState.values.public_id}`, query)
      .then(res => {
        if (res.data.success) {
          // Reset form state
          const form = {...formState};
          form.changes = {};
          form.values = res.data.data;
          ctx.setState({ formState: form });

          // Show success message
          toastr.success("Update successful");

          // Close drawer và trigger reload list
          setDrawerValue(prev => ({
            ...prev,
            mode: modeScreen.view,
            open: false,
            reload: true
          }));

        } else {
          toastr.error(res.data.msg);
        }
      })
      .catch(err => {
        toastr.error("There was an error updating data");
      });
  }

  return (
    <>
      <ResourceList
        rowActive={drawerValue.objectId}
        reload={drawerValue.reload}
        setReLoad={(bool) => setDrawerValue((prev) => ({...prev, reload: bool}))}
        route="/api/v1/users"
        titlePage="SpendAds | All Users"
        title="All Users"
        fields={listFields}
        valueKey="uid"
        onClickRow={(item) => handleClickRow(item)}
        actions={[
          {
            text: 'New User',
            icon: <AddIcon />,
            variant: 'contained',
            visible: true,
            action: (ctx) => newUser(ctx)
          }
        ]}
      />
      {drawerValue.modalConfirmDelete && (<ModalConfirm
        modal={drawerValue.modalConfirmDelete}
        onClose={() => setDrawerValue(pre => ({...pre, modalConfirmDelete: false}))}
        handleSubmit={handleDeleteUser}
        title={'Delete User'}
        content={'Do you want to permanently delete this user? This action is irreversible.'}
      />)}
      <DrawerForm
        fields={formFields}
        mode={drawerValue.mode}
        open={drawerValue.open}
        onClose={() => handleClose()}
        objectId={drawerValue.objectId}
        onLoadData={(public_id) => {
          return new Promise((resolve) => {
            request.get(`/api/v1/user/${public_id}`).then(res => {
              if (res.data.success) {
                resolve(res.data.data)
              } else {
                resolve(false)
              }
            }, err => {
              resolve(false)
            })
          });
        }}
        title="User Detail"
        actions={[
          {
            text: 'Edit',
            variant: 'contained',
            color: 'primary',
            action: () => setDrawerValue(prevState => ({ ...prevState, mode: modeScreen.edit })),
            visible: drawerValue.mode === modeScreen.view
          },
          {
            text: 'Delete',
            variant: 'contained',
            color: 'primary',
            action: (ctx, formState) => {
              setDrawerValue(pre => ({
                ...pre,
                userDeleteId: formState.values.public_id,
                modalConfirmDelete: true
              }));
            },
            visible: drawerValue.mode === modeScreen.view
          },
          {
            text: 'Save',
            variant: 'contained',
            color: 'primary',
            action: (ctx, formState) => handleSave(ctx, formState),
            visible: drawerValue.mode === modeScreen.edit
          },
          {
            text: 'Cancel',
            variant: 'outlined',
            color: 'error',
            action: handleClose,
            visible: drawerValue.mode === modeScreen.edit
          }
        ]}
      />
    </>
  )
}

export default AllUserList;