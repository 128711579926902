import { Button } from '@mui/material';
import { COLOR_WHITE, settingOptions, userOptions } from '../../../_constants_';
import { getButtonColor } from '../../../_helpers_';
import React from 'react';

export const listFields = [
  // {
  //   name: 'mess_group_link',
  //   label: '',
  //   renderValue: (item) => item?.team_id?.mess_group_link ? <a onClick={(e) => e.stopPropagation()}
  //     className='d-flex align-items-center'
  //     href={item?.team_id?.mess_group_link} target="_blank" rel="noreferrer"><IconBrandMessenger size={20} stroke={2} /></a> : '',
  //   width: '3rem',
  // },
  {
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id?.team_id ? `Team_${item.team_id?.team_id}` : '',
    width: '8rem',
    sort: true,
    filter: true,
    filterName: 'teams.team_search',
  },
  {
    name: 'team_username',
    label: 'Team Seller Shortname',
    renderValue: (item) => item.team_id?.team_id ? `${`Team_${item.team_id?.team_id}` || ''} ${item.shortname}` : '',
    sort: true,
    sortName: "name",
    filter: true,
    filterName: 'teams.team_search|name*1',
  },
  {
    name: 'groups',
    label: 'Role',
    renderValue: (item) => item.groups && item.groups.length > 0 ? item.groups.map(e => e.name).join(', ') : '',
  },
  {
    name: 'email',
    label: 'Email Address',
    sort: true,
    filter: true,
  },
  {
    name: 'status',
    label: 'Status',
    sort: false,
    renderValue: (item) => {
      return (<Button
        variant='contained'
        size='small'
        sx={{
          cursor: 'unset !important',
          borderRadius: '12px',
          fontSize: 14,
          color: COLOR_WHITE,
          borderColor: COLOR_WHITE,
          width: '150px',
          textTransform: 'none',
          backgroundColor: `${getButtonColor(item.status, settingOptions)}!important`
        }}
      >
        {item.status ?? '--'}
      </Button>)
    }
  },
]

export const formFields = [
  {
    type: 'text',
    name: 'team_id',
    label: 'Team ID',
    renderValue: (item) => item.team_id?.team_id ? `Team_${item.team_id?.team_id}` : '',
    disabled: true
  },
  {
    type: 'text',
    name: 'team_username',
    label: 'Team Seller Shortname',
    renderValue: (item) => item.team_id?.team_id ? `${`Team_${item.team_id?.team_id}` || ''} ${item.shortname}` : '',
    disabled: true
  },
  {
    type: 'selectOptions',
    name: 'status',
    label: 'Status',
    options: userOptions,
    placeholder: 'Select status',
    renderValue: (item) => item.status ? {value: item.status, label: item.status} : null,
  },
  // {
  //   type: 'multiselect',
  //   name: 'groups',
  //   isClearable: false,
  //   label: 'User - Role',
  //   loadOptions: (inputValue) => {
  //     return new Promise(resolve => {
  //       request.get('/api/v1/groups', { name: inputValue, exclude: JSON.stringify(['admin']) }).then(res => {
  //         if (res.data.success) {
  //           resolve(res.data.data.items.map(e => ({ code: e.code, name: e.name })))
  //         }
  //       })
  //     })
  //   },
  //   valueKey: 'code',
  // },
  {
    type: 'text',
    name: 'groups',
    label: 'Role',
    disabled: true,
    renderValue: (item) => item.groups && item.groups.length > 0 ? item.groups.map(e => e.name).join(', ') : '',
  },
  {
    type: 'text',
    name: 'email',
    disabled: true,
    label: 'Email Address',
  },
  // {
  //   name: 'bank_id',
  //   label: 'Bank Vietnam',
  //   type: 'select_create',
  //   loadOptions: (inputValue) => {
  //     return new Promise(resolve => {
  //       request.get('/api/v1/banks', { search: inputValue }).then(res => {
  //         if (res.data.success) {
  //           resolve(res.data.data.items)
  //         } else {
  //           resolve([])
  //         }
  //       }, error => {
  //         resolve([])
  //       })
  //     })
  //   },
  //   getOptionValue: (item) => item.bank_id,
  //   getOptionLabel: (item) => `${item.name}`,
  //   onCreateOption: (inputValue, ctx) => {
  //     {
  //       request.post('/api/v1/banks', { name: inputValue }).then(res => {
  //         if (res.data.success) {
  //           var e = {
  //             target: {
  //               name: "bank_id",
  //               value: res.data.data
  //             },
  //           };
  //           ctx.handleChange(e)
  //         } else {
  //           toastr.error(res.data.msg)
  //         }
  //       }, err => toastr.error(err))
  //     }
  //   }
  // },
]