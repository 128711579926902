import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { STYLE_TABLE_PAYMENT } from '../../../_constants_/share-style';
import { CustomCircularProgress, Scrollbar } from '../../index';
import { getListRequest } from '../../../_actions_/request-tiktok.actions';
import { BG_COLOR_GREEN, AD_FILTER, PER_PAGE } from '../../../_constants_';
import DrawerEditRequest from './drawerEdit';
import { convertDateWithTimezone, getButtonColor } from '../../../_helpers_';
const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

export default function ListRequestAdAccount() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const listRequest = useSelector((state) => state?.requestTiktok?.listRequests);
  const [selectedRequestDetail, setSelectedRequestDetail] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: PER_PAGE,
    status: AD_FILTER[0].value
  });

  const handleOpenDrawer = (requestId) => {
    setSelectedRequestDetail(requestId);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedRequestDetail(null);
  };

  useEffect(() => {
    if (!listRequest) {
      dispatch(getListRequest(queryObject));
    }
  }, []);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
    dispatch(getListRequest({ ...queryObject, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prevState) => ({
      ...prevState,
      page: 1,
      per_page: event.target.value
    }));
    dispatch(getListRequest({ ...queryObject, per_page: event.target.value }));
  };

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: '8px'
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        p={2}
        sx={{
          borderRadius: '8px',
          overflow: 'hidden',
          justifyContent: 'space-between'
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={0}
          sx={{ borderRadius: '8px', overflow: 'hidden' }}
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                height: '45px',
                backgroundColor: '#fff'
              },
              minWidth: '300px',
              borderRight: 'none'
            }}
            value={queryObject.query}
            onChange={(event) => {
              setQueryObject((prev) => ({
                ...prev,
                query: event.target.value
              }));
            }}
          />
          <Button
            variant="contained"
            sx={{
              height: '45px',
              backgroundColor: BG_COLOR_GREEN,
              color: '#fff',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              '&:hover': {
                backgroundColor: '#007b55'
              }
            }}
            onClick={() => dispatch(getListRequest(queryObject))}
          >
            Search
          </Button>
        </Box>
        <Select
          value={queryObject.status}
          onChange={(event) => {
            setQueryObject((prevState) => ({
              ...prevState,
              status: event.target.value,
              page: 1
            }));
            dispatch(getListRequest({ ...queryObject, status: event.target.value, page: 1 }));
          }}
          sx={{ minWidth: 250 }}
        >
          {AD_FILTER.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box>
        <Scrollbar>
          <Box className="mt-2" style={{ borderTop: '1px solid #ccc' }}>
            <Paper className={classes.root} style={{ position: 'relative' }}>
              <TableContainer className={classes.table}>
                <Table
                  stickyHeader
                  className={clsx(
                    classes.dynamic_col,
                    classes.dynamic_sticky,
                    classes.table,
                    'dynamic_col'
                  )}
                >
                  <TableHead className={classes.head_white}>
                    <TableRow>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '8rem' }}
                      >
                        <Tooltip title={'Request ID'} arrow placement="top">
                          <Box className="line-clamp-1">Request ID</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '10rem' }}
                      >
                        <Tooltip title={'Timestamp'} arrow placement="top">
                          <Box className="line-clamp-1">Timestamp</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '12rem' }}
                      >
                        <Tooltip title={'Status'} arrow placement="top">
                          <Box className="line-clamp-1">Status</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '14rem' }}
                      >
                        <Tooltip title={'Ad account'} arrow placement="top">
                          <Box className="line-clamp-1">Ad account</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '14rem' }}
                      >
                        <Tooltip title={'Business center'} arrow placement="top">
                          <Box className="line-clamp-1">Business center</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '14rem' }}
                      >
                        <Tooltip title={'SpendAds Business Center'} arrow placement="top">
                          <Box className="line-clamp-1">SpendAds Business Center</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '12rem' }}
                      >
                        <Tooltip title={'Time zone'} arrow placement="top">
                          <Box className="line-clamp-1">Time zone</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '8rem' }}
                      >
                        <Tooltip title={'Team ID'} arrow placement="top">
                          <Box className="line-clamp-1">Team ID</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '10rem' }}
                      >
                        <Tooltip title={'Company name'} arrow placement="top">
                          <Box className="line-clamp-1">Company name</Box>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.table_cell,
                          classes.cell_entry,
                          classes.cell_entry_floating,
                          classes.head_cell
                        )}
                        style={{ width: '10rem' }}
                      >
                        <Tooltip title={'Company website'} arrow placement="top">
                          <Box className="line-clamp-1">Company website</Box>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listRequest &&
                      listRequest.items &&
                      listRequest.items.map((item) => {
                        return (
                          <TableRow onClick={() => handleOpenDrawer(item)} key={item.id}>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>Request {item.id}</Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      {convertDateWithTimezone(item.create_date)}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Button
                                  sx={{
                                    cursor: 'unset !important',
                                    borderRadius: '12px',
                                    fontSize: 14,
                                    color: 'white',
                                    borderColor: 'white',
                                    width: '150px',
                                    textTransform: 'none',
                                    backgroundColor: `${getButtonColor(item.status_display)}!important`
                                  }}
                                  variant="outlined"
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box className={classes.text_ellipsis}>
                                        {item.status_display}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Button>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      {item.ads_accounts?.[0]?.name ?? '--'}
                                    </Box>
                                    <Box className={classes.text_ellipsis}>
                                      ID: {item.ads_accounts?.[0]?.advertiser_id}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      {item.bc_name ?? '--'}
                                    </Box>
                                    <Box className={classes.text_ellipsis}>ID: {item.bc_id}</Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      {item.ads_accounts[0]?.owner_bc_name ?? '--'}
                                    </Box>
                                    <Box className={classes.text_ellipsis}>
                                      ID: {item.ads_accounts[0]?.owner_bc_id}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>{item.time_zone}</Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className={classes.text_ellipsis}>Team_{item.team_id}</Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className={classes.text_ellipsis}>
                                    {item.ad_company_name}
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className={classes.text_ellipsis}>{item.ad_company_url}</Box>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {isLoading && (
                <Box
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255,255,255,0.8)'
                  }}
                >
                  <Box className="p-3 d-flex align-items-center justify-content-center">
                    <CustomCircularProgress />
                  </Box>
                </Box>
              )}
            </Paper>
            {listRequest && (
              <TablePagination
                className="list-pagination"
                style={{
                  borderTop: '1px solid #D8D8D8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
                component="div"
                count={listRequest?.total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={queryObject.page - 1}
                rowsPerPage={queryObject.per_page}
                rowsPerPageOptions={[15, 20, 50, 100]}
              />
            )}
          </Box>
        </Scrollbar>
      </Box>
      <DrawerEditRequest
        open={drawerOpen}
        onClose={handleCloseDrawer}
        requestDetail={selectedRequestDetail}
      />
    </Box>
  );
}
