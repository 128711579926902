import * as t from '../_constants_/redux';
import { request } from '../_services_';
import { toastr } from '../_helpers_';

export const getTeamSummary = (query) => async (dispatch) => {
  try {
    const res = await request.get('/admin/v1/admin_team_report', query);
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.TEAM_REPORT,
        payload: data
      });
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
  }
};

export const getTeamOption = () => async (dispatch) => {
  try {
    const res = await request.get('/admin/v1/admin_teams_filter');
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.TEAM_OPTIONS,
        payload: data
      });
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
  }
};
