import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { STYLE_TABLE_PAYMENT } from '../../../_constants_/share-style';
import { Scrollbar } from '../../index';
import { BG_COLOR_GREEN, FORMAT_DATE_Y_M, PER_PAGE, TIME_DELAY } from '../../../_constants_';
import { useLocation } from 'react-router-dom';
import { clearData } from '../../../_actions_';
import DatePickerInput from '../../elements/input-date-picker';
import { getTeamOption, getTeamSummary } from '../../../_actions_/team.actions';
import moment from 'moment';
import { formatNumberWithCommas } from '../../../_helpers_';
import debounce from 'lodash/debounce';
import MultipleSelectCheckbox from '../../elements/multiple-select-checkbox';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

export default function ListTeamReport() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listTeamReport = useSelector((state) => state?.team?.listTeamReport);
  const teamOptions = useSelector((state) => state?.team?.teamOptions);
  const currentMonth = moment().format(FORMAT_DATE_Y_M);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: PER_PAGE,
    team: [],
    start_year_month: currentMonth,
    end_year_month: currentMonth
  });
  const location = useLocation();

  const handleChangeQueryObject = (key, value) => {
    setQueryObject({ ...queryObject, [key]: value });
  };

  useEffect(() => {
    if (!teamOptions) {
      dispatch(getTeamOption());
    }
  }, []);

  useEffect(() => {
    if (listTeamReport) dispatch(clearData('listTeamReport'));
  }, [location]);

  useEffect(() => {
    if (!listTeamReport) {
      dispatch(getTeamSummary(queryObject));
    }
  }, [listTeamReport]);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
    dispatch(getTeamSummary({ ...queryObject, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prevState) => ({
      ...prevState,
      page: 1,
      per_page: event.target.value
    }));
    dispatch(getTeamSummary({ ...queryObject, page: 1, per_page: event.target.value }));
  };

  const debouncedApiCall = useCallback(
    debounce((params) => {
      dispatch(getTeamSummary(params));
    }, TIME_DELAY),
    []
  );

  useEffect(() => {
    if (listTeamReport) {
      const params = {
        ...queryObject,
        team_ids: queryObject.team.join(',')
      };
      debouncedApiCall(params);
    }
  }, [queryObject.team, queryObject.start_year_month, queryObject.end_year_month]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box
        sx={{
          border: '1px solid #D8D8D8',
          p: 2,
          borderRadius: '8px',
          display: 'flex',
          gap: {
            xs: '10px',
            sm: '20px',
            md: '30px',
            lg: '106px'
          },
          flexWrap: 'wrap'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center'
          }}
        >
          <Typography variant="h6" style={{ fontSize: '16px' }}>
            Total Spent
          </Typography>
          <Box
            sx={{
              border: '1px solid #D8D8D8',
              borderRadius: '8px',
              p: '8px 16px',
              minWidth: '150px'
            }}
          >
            $ {formatNumberWithCommas(listTeamReport?.overview?.spent_sum)}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center'
          }}
        >
          <Typography variant="h6" style={{ fontSize: '16px' }}>
            Total Credit
          </Typography>
          <Box
            sx={{
              border: '1px solid #D8D8D8',
              borderRadius: '8px',
              p: '8px 16px',
              minWidth: '150px'
            }}
          >
            $ {formatNumberWithCommas(listTeamReport?.overview?.free_credit_sum)}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center'
          }}
        >
          <Typography variant="h6" style={{ fontSize: '16px' }}>
            Total Balance
          </Typography>
          <Box
            sx={{
              border: '1px solid #D8D8D8',
              borderRadius: '8px',
              p: '8px 16px',
              minWidth: '150px'
            }}
          >
            $ {formatNumberWithCommas(listTeamReport?.overview?.total_fund_sum)}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: '8px'
        }}
      >
        {/*search*/}
        <Box
          display="flex"
          alignItems="center"
          p={2}
          sx={{
            borderRadius: '8px',
            overflow: 'hidden',
            gap: {
              xs: '10px',
              sm: '20px',
              md: '30px',
              lg: 3
            },
            flexWrap: 'wrap'
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={0}
            sx={{ borderRadius: '8px', overflow: 'hidden' }}
          >
            <TextField
              variant="outlined"
              placeholder="Search"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0,
                  height: '48px',
                  backgroundColor: '#fff'
                },
                minWidth: '300px',
                borderRight: 'none'
              }}
              value={queryObject.search}
              onChange={(event) => {
                setQueryObject((prev) => ({
                  ...prev,
                  search: event.target.value
                }));
              }}
            />
            <Button
              variant="contained"
              sx={{
                height: '48px',
                backgroundColor: BG_COLOR_GREEN,
                color: '#fff',
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                '&:hover': {
                  backgroundColor: '#007b55'
                }
              }}
              onClick={() =>
                dispatch(getTeamSummary({ ...queryObject, team_ids: queryObject.team.join(',') }))
              }
            >
              Search
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MultipleSelectCheckbox
              placeholder="Select Teams"
              value={queryObject.team}
              options={teamOptions}
              onChange={(key, newValue) => {
                handleChangeQueryObject('team', newValue);
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box width={'150px'} height={'50px'}>
              <DatePickerInput
                value={queryObject.start_year_month}
                onChange={(key, value) => {
                  handleChangeQueryObject('start_year_month', value);
                }}
                maxDate={queryObject.end_year_month}
                placeholder="Start date"
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>~</Box>
            <Box width={'150px'} height={'50px'}>
              <DatePickerInput
                value={queryObject.end_year_month}
                onChange={(key, value) => {
                  handleChangeQueryObject('end_year_month', value);
                }}
                minDate={queryObject.start_year_month}
                placeholder="End date"
              />
            </Box>
          </Box>
        </Box>

        {/*table*/}
        <Box>
          <Scrollbar>
            <Box className="mt-2" style={{ borderTop: '1px solid #ccc' }}>
              <Paper className={classes.root} style={{ position: 'relative' }}>
                <TableContainer className={classes.table}>
                  <Table
                    stickyHeader
                    className={clsx(
                      classes.dynamic_col,
                      classes.dynamic_sticky,
                      classes.table,
                      'dynamic_col'
                    )}
                  >
                    <TableHead className={classes.head_white}>
                      <TableRow>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '100px' }}
                        >
                          <Tooltip title={'Team ID'} arrow placement="top">
                            <Box className="line-clamp-1">Team ID</Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '100px' }}
                        >
                          <Tooltip title={'Number of account '} arrow placement="top">
                            <Box className="line-clamp-1">Number of account </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '120px' }}
                        >
                          <Tooltip title={'Spent'} arrow placement="top">
                            <Box className="line-clamp-1">Spent</Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '130px' }}
                        >
                          <Tooltip title={'Credit'} arrow placement="top">
                            <Box className="line-clamp-1">Credit</Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '190px' }}
                        >
                          <Tooltip title={'Balance'} arrow placement="top">
                            <Box className="line-clamp-1">Balance</Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '150px' }}
                        >
                          <Tooltip title={'Owner'} arrow placement="top">
                            <Box className="line-clamp-1">Owner</Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.table_cell,
                            classes.cell_entry,
                            classes.cell_entry_floating,
                            classes.head_cell
                          )}
                          style={{ width: '150px' }}
                        >
                          <Tooltip title={'Sale convert'} arrow placement="top">
                            <Box className="line-clamp-1">Sale convert</Box>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listTeamReport &&
                        listTeamReport.items &&
                        listTeamReport.items.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>Team_{item.team_id}</Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      {item.ads_acc_count}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      ${formatNumberWithCommas(item?.total_cost_usd)}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      ${formatNumberWithCommas(item?.free_credit)}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      ${formatNumberWithCommas(item?.total_fund)}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>{item?.owner_email}</Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className={clsx(classes.cell_entry)}>
                              <Box
                                className="fnu-view"
                                style={{ position: 'relative', overflow: 'hidden' }}
                              >
                                <Box className={classes.content_inline}>
                                  <Box className="text_2">
                                    <Box className={classes.text_ellipsis}>
                                      {item?.sale_convert}
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              {listTeamReport && (
                <TablePagination
                  className="list-pagination"
                  style={{
                    borderTop: '1px solid #D8D8D8',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                  component="div"
                  count={listTeamReport?.total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  page={queryObject.page - 1}
                  rowsPerPage={queryObject.per_page}
                  rowsPerPageOptions={[15, 20, 50, 100]}
                />
              )}
            </Box>
          </Scrollbar>
        </Box>
      </Box>
    </Box>
  );
}
