import React, { useEffect, useState } from 'react';
import Page from '../../../components/Page';
import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { STYLE_TABLE_PAYMENT } from '../../../_constants_/share-style';
import { BG_COLOR_GREEN, COLOR_WHITE, PAGINATE_ARR, PER_PAGE, refundStatus } from '../../../_constants_';
import { CustomCircularProgress, Scrollbar } from '../../../components';
import clsx from 'clsx';
import { formatDateToTimezone, getButtonColor } from '../../../_helpers_';
import { makeStyles } from '@mui/styles';
import DrawerEditRefund from '../../../components/app3/Refund/drawerEdit';
import { useLocation } from 'react-router-dom';
import { clearData } from '../../../_actions_';
import { getListRefund } from '../../../_actions_/refund.actions';

const useStyles = makeStyles(() => ({ ...STYLE_TABLE_PAYMENT }));

export default function RequestTiktokRefund() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const listRefund = useSelector((state) => state?.refund?.listRefund);
  const [queryObject, setQueryObject] = useState({
    page: 1,
    per_page: PER_PAGE
  });
  const dispatch = useDispatch();
  const [detail, setDetail] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!listRefund) {
      dispatch(getListRefund(queryObject));
    }
  }, [listRefund]);

  useEffect(() => {
    if (listRefund) dispatch(clearData('listRefund'));
  }, [location]);

  const handlePageChange = (event, page) => {
    setQueryObject((prev) => ({
      ...prev,
      page: page + 1
    }));
    dispatch(getListRefund({ ...queryObject, page: page + 1 }));
  };

  const handleRowsPerPageChange = (event) => {
    setQueryObject((prevState) => ({
      ...prevState,
      page: 1,
      per_page: event.target.value
    }));
    dispatch(getListRefund({ ...queryObject, per_page: event.target.value }));
  };

  const handleOpenDrawer = (refund) => {
    setDetail(refund);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setDetail(null);
  };

  return (
    <Page title="SpendAds | Request titok refund">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box className="d-flex align-items-center">
            <Typography variant="h4">Request Tiktok Refund</Typography>
          </Box>
        </Stack>
        <Box mt={3}>
          <Box className="box-table-search">
            <Box
              display="flex"
              alignItems="center"
              p={2}
              gap={0}
              sx={{ borderRadius: '8px', overflow: 'hidden' }}
            >
              <TextField
                variant="outlined"
                placeholder="Search"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    height: '45px',
                    backgroundColor: COLOR_WHITE
                  },
                  minWidth: '300px',
                  borderRight: 'none'
                }}
                value={queryObject.keyword}
                onChange={(event) => {
                  setQueryObject((prev) => ({
                    ...prev,
                    keyword: event.target.value
                  }));
                }}
              />
              <Button
                variant="contained"
                sx={{
                  height: '45px',
                  backgroundColor: BG_COLOR_GREEN,
                  color: COLOR_WHITE,
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0
                }}
                onClick={() => {
                  dispatch(getListRefund(queryObject));
                }}
              >
                Search
              </Button>
            </Box>

            <Scrollbar>
              <Box className="mt-2" style={{ borderTop: '1px solid #ccc' }}>
                <Paper className={classes.root} style={{ position: 'relative' }}>
                  <TableContainer className={classes.table}>
                    <Table
                      stickyHeader
                      className={clsx(
                        classes.dynamic_col,
                        classes.dynamic_sticky,
                        classes.table,
                        'dynamic_col'
                      )}
                    >
                      <TableHead className={classes.head_white}>
                        <TableRow>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '8rem' }}
                          >
                            <Tooltip title={'Request ID'} arrow placement="top">
                              <Box className="line-clamp-1">Request ID</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '10rem' }}
                          >
                            <Tooltip title={'Timestamp'} arrow placement="top">
                              <Box className="line-clamp-1">Timestamp</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '12rem' }}
                          >
                            <Tooltip title={'Status'} arrow placement="top">
                              <Box className="line-clamp-1">Status</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '12rem' }}
                          >
                            <Tooltip title={'Requested Amount'} arrow placement="top">
                              <Box className="line-clamp-1">Requested Amount</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '10rem' }}
                          >
                            <Tooltip title={'Refund reason'} arrow placement="top">
                              <Box className="line-clamp-1">Refund reason</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '10rem' }}
                          >
                            <Tooltip title={'Detail reason'} arrow placement="top">
                              <Box className="line-clamp-1">Detail reason</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '10rem' }}
                          >
                            <Tooltip title={'Receiving method'} arrow placement="top">
                              <Box className="line-clamp-1">Receiving method</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '14rem' }}
                          >
                            <Tooltip title={'Recipient Information'} arrow placement="top">
                              <Box className="line-clamp-1">Recipient Information</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '10rem' }}
                          >
                            <Tooltip title={'Team ID'} arrow placement="top">
                              <Box className="line-clamp-1">Team ID</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '10rem' }}
                          >
                            <Tooltip title={'Note'} arrow placement="top">
                              <Box className="line-clamp-1">Note</Box>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.table_cell,
                              classes.cell_entry,
                              classes.cell_entry_floating,
                              classes.head_cell
                            )}
                            style={{ width: '13rem' }}
                          >
                            <Tooltip title={'Operator'} arrow placement="top">
                              <span className="line-clamp-1">Operator</span>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listRefund &&
                          listRefund.items &&
                          listRefund.items.map((item) => (
                            <TableRow key={item.id} onClick={() => handleOpenDrawer(item)}>
                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box
                                        className={classes.text_ellipsis}
                                      >{`Request ${item.id}`}</Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box className={classes.text_ellipsis}>
                                        {formatDateToTimezone(item.create_date)}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>

                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Button
                                    sx={{
                                      cursor: 'unset !important',
                                      borderRadius: '12px',
                                      fontSize: 14,
                                      color: COLOR_WHITE,
                                      borderColor: COLOR_WHITE,
                                      width: '150px',
                                      textTransform: 'none',
                                      backgroundColor: `${getButtonColor(item.status, refundStatus)}!important`
                                    }}
                                    variant="outlined"
                                  >
                                    <Box className={classes.content_inline}>
                                      <Box className="text_2">
                                        <Box className={classes.text_ellipsis}>{item.status}</Box>
                                      </Box>
                                    </Box>
                                  </Button>
                                </Box>
                              </TableCell>
                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box className={classes.text_ellipsis}>${item.amount}</Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box className={classes.text_ellipsis}>
                                        {item.refund_reason}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box className={classes.text_ellipsis}>
                                        {item.detail_reason}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box className={classes.text_ellipsis}>
                                        {item.receive_method}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box className={classes.text_ellipsis}>
                                        {item.receiving_info}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box className={classes.text_ellipsis}>
                                        Team_ {item.team_id}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box className={classes.text_ellipsis}>{item.note}</Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell className={clsx(classes.cell_entry)}>
                                <Box
                                  className="fnu-view"
                                  style={{ position: 'relative', overflow: 'hidden' }}
                                >
                                  <Box className={classes.content_inline}>
                                    <Box className="text_2">
                                      <Box className={classes.text_ellipsis}>
                                        {item.user_execute_email}
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {isLoading && (
                    <Box
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(255,255,255,0.8)'
                      }}
                    >
                      <Box className="p-3 d-flex align-items-center justify-content-center">
                        <CustomCircularProgress />
                      </Box>
                    </Box>
                  )}
                </Paper>
                {listRefund && (
                  <TablePagination
                    className="list-pagination"
                    style={{
                      borderTop: '1px solid #d8d8d8',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start'
                    }}
                    component="div"
                    count={listRefund?.total}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={queryObject.page - 1}
                    rowsPerPage={queryObject.per_page}
                    rowsPerPageOptions={PAGINATE_ARR}
                  />
                )}
              </Box>
            </Scrollbar>
          </Box>

          {detail && (
            <DrawerEditRefund open={drawerOpen} onClose={handleCloseDrawer} refund={detail} />
          )}
        </Box>
      </Container>
    </Page>
  );
}
