import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormHelperText, Typography } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import React from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { COLOR_RED, FORMAT_DATE_Y_M } from '../../_constants_';

export default function DatePickerInput(props) {
  const {
    title,
    value,
    onChange,
    keyword,
    error,
    disable = false,
    required = false,
    dateFormat = FORMAT_DATE_Y_M,
    minDate = null,
    maxDate = null
  } = props;

  const parsedValue =
    value && moment(value, dateFormat).isValid() ? moment(value, dateFormat) : null;

  const parsedMinDate =
    minDate && moment(minDate, dateFormat).isValid() ? moment(minDate, dateFormat) : null;

  const parsedMaxDate =
    maxDate && moment(maxDate, dateFormat).isValid() ? moment(maxDate, dateFormat) : null;

  const handleChange = (value) => {
    if (onChange) {
      onChange(keyword || '', value ? value.format(dateFormat) : null);
    }
  };

  return (
    <Box>
      {title && (
        <Typography>
          {title}
          {required && <StarRateRoundedIcon sx={{ fontSize: 'small' }} />}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          slots={{
            openPickerIcon: CalendarTodayOutlinedIcon
          }}
          slotProps={{
            field: {
              readOnly: true
            }
          }}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              height: '48px'
            },
            '& .MuiInputAdornment-root .MuiSvgIcon-root': {
              marginRight: '12px'
            }
          }}
          views={['year', 'month']}
          format={dateFormat}
          value={parsedValue}
          onChange={handleChange}
          disabled={disable}
          minDate={parsedMinDate}
          maxDate={parsedMaxDate}
        />
      </LocalizationProvider>
      {error && <FormHelperText sx={{ color: COLOR_RED }}>{error}</FormHelperText>}
    </Box>
  );
}
