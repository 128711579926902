import * as t from '../_constants_/redux';
import { request } from '../_services_';
import { toastr } from '../_helpers_';
import { EDIT_REFUND } from '../_constants_/redux';

export const getListRefund = (query) => async (dispatch) => {
  try {
    const res = await request.get('/admin/v1/refunds', query);
    const { success, data, msg } = res.data;
    if (success) {
      dispatch({
        type: t.LIST_REFUND,
        payload: data
      });
    } else {
      toastr.error(msg);
    }
  } catch (err) {
    toastr.error(err);
  }
};

export const updateRefund = (id, data) => async (dispatch) => {
  try {
    const res = await request.put_form_data(`/admin/v1/refund/${id}`, data);
    if (res.data.success) {
      dispatch({
        type: t.EDIT_REFUND,
        payload: res.data.data
      });
      toastr.success('Edit a request refund successful!');
    }
  } catch (err) {
    // toastr.error('Can only update refund request in status process');
    dispatch({
      type: t.ERROR,
      payload: {
        key: 'editRefundErrors',
        errors: err.response.data
      }
    });
  }
};
