import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  TextField,
  Popper,
  Paper,
  ClickAwayListener,
  IconButton,
  InputAdornment
} from '@mui/material';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import { IconX, IconChevronDown, IconSearch } from '@tabler/icons';
import { BG_COLOR_GREEN } from '../../_constants_';

export default function MultipleSelectCheckbox(props) {
  const {
    title,
    placeholder,
    classNameSpecificUI,
    value,
    options = [],
    onChange,
    error,
    disabled = false,
    required = false
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const anchorRef = useRef(null);

  // Filter options when search value changes
  useEffect(() => {
    const filtered = options?.filter(option =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchValue, options]);

  const selectedValues = Array.isArray(value) ? value : [];

  const handleToggleOption = (optionValue) => {
    let newValues;
    if (selectedValues.includes(optionValue)) {
      newValues = selectedValues?.filter(v => v !== optionValue);
    } else {
      newValues = [...selectedValues, optionValue];
    }
    onChange(props.keyword || '', newValues);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    onChange(props.keyword || '', []);
  };

  return (
    <Box className={classNameSpecificUI}>
      {title && (
        <Typography variant="body1">
          {title}
          {required && <StarRateRoundedIcon fontSize="small" color={'error'}/>}
        </Typography>
      )}

      <Box
        ref={anchorRef}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        sx={{
          border: `1px solid ${isOpen ? BG_COLOR_GREEN : '#e4e4e4'}`,
          borderRadius: 1,
          py: 1,
          px: 1.5,
          cursor: disabled ? 'not-allowed' : 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: disabled ? 'action.disabledBackground' : 'background.paper',
          minHeight: 48,
          width: 200,
          mx: 1
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: selectedValues.length > 0 ? 'text.primary' : 'text.secondary',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {selectedValues.length > 0
            ? `${selectedValues.length} items selected`
            : (placeholder || 'Select options')}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {selectedValues.length > 0 && (
            <IconButton
              size="small"
              onClick={handleClear}
              sx={{ p: 0.5 }}
            >
              <IconX size={16} stroke={1} />
            </IconButton>
          )}
          <IconChevronDown size={16} stroke={1} />
        </Box>
      </Box>

      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        style={{ width: 220, marginTop: 4, zIndex: 1300 }}
      >
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Paper elevation={3}>
            <Box sx={{ p: 1.5, pb: 1 }}>
              <TextField
                autoFocus
                fullWidth
                size="small"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ mr: 1 }}>
                      <IconSearch size={16}/>
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
              {filteredOptions?.length === 0 ? (
                <Typography
                  variant="body2"
                  sx={{ p: 1.5, color: 'text.secondary' }}
                >
                  No results found
                </Typography>
              ) : (
                filteredOptions?.map(option => (
                  <FormControlLabel
                    key={option.value}
                    control={
                      <Checkbox
                        size="small"
                        checked={selectedValues.includes(option.value)}
                        onChange={() => handleToggleOption(option.value)}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {option.label}
                      </Typography>
                    }
                    sx={{
                      m: 0,
                      px: 1.5,
                      py: 0.5,
                      width: '100%',
                      '&:hover': {
                        bgcolor: 'action.hover'
                      }
                    }}
                  />
                ))
              )}
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>

      {error && (
        <FormHelperText error>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
}
