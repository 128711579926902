import { adStatus, FORMAT_DATE, FORMAT_DATE_Y_M, targetMarketOption } from '../_constants_';
import moment from 'moment';

export const getButtonColor = (statusValue, statusOption = adStatus) => {
  const status = statusOption.find((item) => item.value === statusValue);

  if (!status) return 'purple';

  switch (status.color) {
    case 'warning':
      return '#ffc107';
    case 'success':
      return '#28a745';
    case 'error':
      return '#dc3545';
    default:
      return 'secondary';
  }
};

export const getLabelById = (id, options = targetMarketOption) => {
  const target = options.find((option) => option.value === id);
  return target ? target.label : null;
};

export const formatDataOptionBC = (data, textBreak = '\n') => {
  if (!data) return [];
  return data.map((item) => ({
    value: item.value,
    label: `${item.label}${textBreak}ID: ${item.value}`
  }));
};

export const extractNumber = (str) => {
  const match = str.match(/\d+/);
  return match ? match[0] : null;
};

// convert timestamp UTC +7
// exam: input UTC +0 - Fri, 08 Nov 2024 04:50:44 GMT; output UTC +7 - YYYY/MM/DD hh:mm:ss
export const formatDateToTimezone = (inputDate) => {
  if (!inputDate) {
    return '--';
  }

  const date = new Date(inputDate);
  const options = {
    timeZone: 'Asia/Bangkok',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };
  const parts = date.toLocaleString('en-CA', options).split(', ');

  return `${parts[0].replace(/-/g, '/')} ${parts[1]}`;
};

// convert timestamp UTC +7
// exam: input UTC +0 - 25/11/2024 07:49:53; output UTC +7 - YYYY/MM/DD hh:mm:ss
export const convertDateWithTimezone = (inputDate) => {
  if (!inputDate) {
    return '--';
  }

  // Parse input Date
  const [datePart, timePart] = inputDate.split(' ');
  const [day, month, year] = datePart.split('/').map(Number);
  const [hour, minute, second] = timePart.split(':').map(Number);

  const dateUTC = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
  // UTC+7
  const options = {
    timeZone: 'Asia/Bangkok',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };
  const parts = dateUTC.toLocaleString('en-CA', options).split(', ');
  return `${parts[0].replace(/-/g, '/')} ${parts[1]}`;
};

export const convertDateFormatParam = (dateString) => {
  if (!dateString) return null;
  return moment(dateString, FORMAT_DATE).format(FORMAT_DATE_Y_M);
};

export function formatNumberWithCommas(number, style = false) {
  if (typeof number !== 'number' || isNaN(number)) return '--';
  let options = { currency: 'USD', maximumFractionDigits: 2 };
  if (style) options = { ...options, style: 'currency' };
  const currencyFormatter = new Intl.NumberFormat('en-US', options);
  return currencyFormatter.format(number);
}

export const getImageUrl = (imagePath) => {
  return `${process.env.REACT_APP_API_URL}/images/${imagePath}`;
};
